.powerbi-container {
    height: 500px; /* Ajusta la altura según tus necesidades */
    width: 100%;
}

.reportClass {
    height: 100%;
    background-color: rgb(255, 255, 255);
    width: 60%;
    margin:auto;
}